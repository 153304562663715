"use client";

import ArrayUtils from "../../common/utils/ArrayUtils";
import React, { useEffect, useState } from "react";
import { Button, Pagination } from "react-bootstrap";
import styles from "./pagination.module.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface IProps {
    allPagesNumber: number;
    currentPage: number;
    boundary?: number;
    adiacents?: number;
    pageChange: (page: number) => void;
    handleSeeAll?: () => void;
    seeAllButton?: boolean;
}

const ELIPSSIS = "elipssis";

const getPages = (currentPage: number, adiacents: number, boundary: number, total: number): (string | number)[] => {
    const startPages = ArrayUtils.range(1, Math.min(boundary, total));
    const endPages = ArrayUtils.range(Math.max(total - boundary + 1, boundary + 1), total);

    const start = Math.max(Math.min(currentPage - adiacents, total - boundary - adiacents), boundary);
    const startElipssis = start > boundary + 1 ? [`start-${ELIPSSIS}`] : boundary + 1 < total - boundary ? [boundary + 1] : [];

    const end = Math.min(Math.max(currentPage + adiacents), endPages.length > 0 ? endPages[0] : total - 1);
    const endElipssis = end < total - boundary ? [`end-${ELIPSSIS}`] : total - boundary > boundary ? [total - boundary] : [];

    const result = [...startPages, ...startElipssis, ...ArrayUtils.range(start, end), ...endElipssis, ...endPages];
    return result.length > 0 ? Array.from(new Set(result)) : [1];
};

const Paginations: React.FC<IProps> = ({ currentPage, allPagesNumber, adiacents = 1, boundary = 1, pageChange, handleSeeAll, seeAllButton = true }) => {
    const [hydrated, setHydrated] = React.useState(false);
    const [paginationData, setPaginationData] = useState<(string | number)[]>(getPages(currentPage, adiacents, boundary, allPagesNumber));

    useEffect(() => {
        setPaginationData(getPages(currentPage, adiacents, boundary, allPagesNumber));
    }, [currentPage, allPagesNumber]);

    if (allPagesNumber > 10) {
        allPagesNumber = 10;
    }

    const onPageChange = (page: number) => {
        document.getElementById("table-scroll")?.scrollIntoView({ behavior: "smooth" });
        pageChange(page);
    };

    const onHandleSeeAll = () => {
        if (handleSeeAll) {
            handleSeeAll();
        }
    };

    React.useEffect(() => {
        setHydrated(true);
    }, []);

    if (!hydrated || !paginationData) {
        return <></>;
    }

    return (
        <Pagination className={styles.paginationWrapper}>
            <Pagination.Item disabled={currentPage <= 1} onClick={() => onPageChange(currentPage - 1)}>
                <span>
                    <FaChevronLeft />
                </span>
            </Pagination.Item>
            {(paginationData || []).map((page, key) =>
                typeof page === "number" ? (
                    <Pagination.Item
                        key={key}
                        active={page === currentPage}
                        disabled={page === currentPage}
                        className={`pagination__button pagination__page-previous ${currentPage === 1 && "pagination__button--disabled"}`}
                        onClick={() => onPageChange(page as number)}
                    >
                        {page}
                    </Pagination.Item>
                ) : (
                    <Pagination.Ellipsis key={key} />
                ),
            )}
            <Pagination.Item
                disabled={currentPage >= allPagesNumber}
                onClick={() => onPageChange(currentPage + 1)}
                className={`pagination__button pagination__page-next ${currentPage === allPagesNumber && "pagination__button--disabled"}`}
            >
                <span>
                    <FaChevronRight />
                </span>
            </Pagination.Item>
            {seeAllButton && (
                <div className="ms-2">
                    <Button onClick={onHandleSeeAll} variant="primary">
                        Vezi toate
                    </Button>
                </div>
            )}
        </Pagination>
    );
};

export default Paginations;
