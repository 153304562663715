"use client";

import React, { useState } from "react";
import style from "./readmore.module.scss";
import { Button } from "react-bootstrap";

export interface ReadMoreProps {
    text: string;
    maxCharNumber?: number;
}

function readTextHtml(text: string) {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
}

export const ReadMore = ({ text, maxCharNumber }: ReadMoreProps) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <div className={style.textSection}>
            <div className={style.text}>
                {isReadMore ? readTextHtml(text.slice(0, maxCharNumber) + "...") : readTextHtml(text)}
                <Button variant="default" onClick={toggleReadMore} className={style.readOrHide}>
                    {isReadMore ? "vezi mai mult" : "vezi mai putin"}
                </Button>
            </div>
        </div>
    );
};

export default ReadMore;
