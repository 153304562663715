import {backendUrl, domainUrl} from "@/lib/Constants";
import {doGet} from "@/common/helpers/fetch.helpers";
import {ConfigurationStepsProducts, Configurators, ConfiguratorsPaginatedResponse,} from "@/common/entities/configurators/Configurator";
import {CurrencyExchangeRates} from "@/common/entities/currencyExchangeRates/CurrencyExchangeRates";

export async function getConfigurators(slug: string): Promise<ConfiguratorsPaginatedResponse> {
    const params: Record<string, string> = {
        siteDomain: domainUrl,
    };

    return doGet<ConfiguratorsPaginatedResponse>(`${backendUrl}/configurators/public/${slug}`, params);
}

export async function getConfiguratorDetailsBySlug(slug: string): Promise<Configurators> {
    return doGet<Configurators>(`${backendUrl}/configurators/public-details/${slug}`);
}

export async function getLatestValueForEuro(): Promise<CurrencyExchangeRates> {
    return doGet<CurrencyExchangeRates>(`${backendUrl}/currency-exchange-rates/latest/euro`);
}

export async function getFilteredProducts(
    configuratorStepId: number,
    characteristicsIds: number[],
): Promise<ConfigurationStepsProducts[]> {
    const url = new URL(`${backendUrl}/configurators/steps/products/public?siteDomain=${domainUrl}`);
    url.searchParams.append("configuratorStepId", configuratorStepId.toString());
    url.searchParams.append("characteristicsIds", characteristicsIds.join(","));

    return doGet<ConfigurationStepsProducts[]>(url.toString());
}
