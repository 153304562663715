"use client";

import React from "react";
import { Form } from "react-bootstrap";

export interface FilterOrderBy {
    orderBy: string;
    orderDirection: "ASC" | "DESC";
}

interface CategoryProductsSortingProps {
    onSort: (order: FilterOrderBy) => void;
    filters: { [key: string]: string | undefined };
}

const CategoryProductsSorting = ({ onSort, filters }: CategoryProductsSortingProps) => {
    const onChange = (e: any) => {
        const value = e.target.value;
        if (!value) {
            onSort({ orderBy: "", orderDirection: "ASC" });
        } else {
            const [orderBy, orderDirection] = value.split("-");
            onSort({ orderBy, orderDirection });
        }
    };

    const getActiveSorting = () => {
        if (filters && filters.orderBy && filters.orderDirection) {
            return `${filters.orderBy}-${filters.orderDirection}`;
        }

        return undefined;
    };

    return (
        <>
            <Form.Label className="me-3 m-0 d-none d-md-block">Sorteaza:</Form.Label>
            <Form.Select aria-label="Products filtering" onChange={onChange} value={getActiveSorting()}>
                <option value="products.availableQuantity-DESC">In stoc</option>
                <option value="products.priceWithVat-ASC">Pret crescator</option>
                <option value="products.priceWithVat-DESC">Pret descrescator</option>
                <option value="products.name-ASC">A - Z</option>
                <option value="products.name-DESC">Z - A</option>
            </Form.Select>
        </>
    );
};

export default CategoryProductsSorting;
