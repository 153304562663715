"use client";

import React, { useState } from "react";
import { GroupedAttributesFilters } from "@/common/entities/product/Attributes";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import style from "@/components/Products/cateogry.module.scss";

interface SingleAttributeSideFilterProps {
    onIntervalFilter: (intervalId: number, filterable?: boolean) => void;
    filters: { [key: string]: string | undefined };
    attributesGroups: GroupedAttributesFilters[];
    onCharacteristicsFilter: (characteristicId: number, filterable?: boolean) => void;
}

const SingleAttributeSideFilter = ({
                                       onIntervalFilter, filters, attributesGroups,onCharacteristicsFilter
                                   }: SingleAttributeSideFilterProps) => {
    const [searchTerms, setSearchTerms] = useState<Record<string, string>>({});
    const [showMore, setShowMore] = useState<Record<string, boolean>>({});

    const isIntervalChecked = (intervalId: number) =>
        filters.intervals?.split(",").includes(intervalId.toString()) || false;

    const isCharacteristicChecked = (characteristicId: number) =>
        filters.characteristics?.split(",").includes(characteristicId.toString()) || false;

    const renderIntervals = (group: GroupedAttributesFilters) =>
        group.intervals
            .filter((option) => Number(option.total) !== 0)
            .map((option, idx) => (
                <div className="d-flex justify-content-between align-items-center" key={idx}>
                    <Form.Check
                        type="checkbox"
                        label={option.intervalName}
                        id={`${group.attributeName}-${idx}`}
                        onChange={() => onIntervalFilter(option.intervalId)}
                        checked={isIntervalChecked(option.intervalId)}
                    />
                    <small className="text-secondary">({option.total})</small>
                </div>
            ));

    const renderCharacteristics = (group: GroupedAttributesFilters) =>
        group.characteristics
            .filter((char) =>
                char.characteristicName
                    .toLowerCase()
                    .includes(searchTerms[group.attributeName]?.toLowerCase() || "")
            )
            .slice(0, showMore[group.attributeName] ? group.characteristics.length : 8)
            .map((option, idx) => (
                <div className="d-flex justify-content-between align-items-center" key={idx}>
                    <Form.Check
                        type="checkbox"
                        label={option.characteristicName}
                        id={`${group.attributeName}-${idx}`}
                        onChange={() => onCharacteristicsFilter(option.id)}
                        checked={isCharacteristicChecked(option.id)}
                    />
                    <small className="text-secondary">({option.count})</small>
                </div>
            ));

    const renderGroup = (group: GroupedAttributesFilters) => {
        if (group.intervals?.length > 0) {
            return (
                <div className={style.cardFilter} key={group.attributeName}>
                    <h5 className={style.cardTitle}>{group.attributeName}</h5>
                    {renderIntervals(group)}
                </div>
            );
        }

        if (group.characteristics?.length > 0) {
            return (
                <div className={style.cardFilter} key={group.attributeName}>
                    <h5 className={style.cardTitle}>{group.attributeName}</h5>
                    {group.characteristics.length > 8 && (
                        <InputGroup size="sm" className="mb-3">
                            <FormControl
                                placeholder="Cauta..."
                                aria-label="Search"
                                value={searchTerms[group.attributeName]}
                                onChange={(e) =>
                                    setSearchTerms((prev) => ({
                                        ...prev,
                                        [group.attributeName]: e.target.value,
                                    }))
                                }
                            />
                        </InputGroup>
                    )}
                    {renderCharacteristics(group)}
                    {group.characteristics.length > 8 && (
                        <Button
                            size="sm"
                            className="text-center d-block w-100 mt-2"
                            variant="link"
                            onClick={() =>
                                setShowMore((prev) => ({
                                    ...prev,
                                    [group.attributeName]: !prev[group.attributeName],
                                }))
                            }
                        >
                            {showMore[group.attributeName] ? "Vezi mai putine" : "Vezi mai multe"}
                        </Button>
                    )}
                </div>
            );
        }

        return null;
    };

    return <>{attributesGroups.map(renderGroup)}</>;
};

export default SingleAttributeSideFilter;