import React from "react";
import styles from "@/components/ProductCard/productCard.module.scss";
import { Card } from "react-bootstrap";
import Link from "next/link";
import { ImageWrapper } from "@/common/components/Images/ImageWrapper";
import { endStringEllipsis } from "@/common/helpers/util.functions";
import { FaCircle } from "react-icons/fa";

interface ConfiguratorCardProps {
    imageSrc?: string;
    configuratorName: string;
    shortDescription: string;
    className?: string;
    url: string;
}

const ConfiguratorCard = ({ imageSrc, configuratorName, shortDescription, className = "", url }: ConfiguratorCardProps) => {
    return (
        <Link className={className} href={url}>
            <Card className={styles.productCard}>
                <div className={styles.imageWrapper}>
                    <ImageWrapper width={240} height={240} url={imageSrc} title={configuratorName} />
                </div>
                <div className={styles.productCardInfo}>
                    <h2 className={styles.productName}>{configuratorName}</h2>
                    <div className={styles.inStock}>
                        <div className={styles.productStock}>
                            <FaCircle className="me-2" /> Configureaza
                        </div>
                    </div>
                    <div className={styles.productDescription} dangerouslySetInnerHTML={{ __html: endStringEllipsis(shortDescription, 125) }}></div>
                    <div className={`${styles.productPriceWrapper} mt-2`}>
                        <div className={styles.productPrice}>Calculeaza pret!</div>
                    </div>
                </div>
            </Card>
        </Link>
    );
};

export default ConfiguratorCard;
