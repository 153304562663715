"use client";

import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import CategoryProductsSorting, { FilterOrderBy } from "@/components/Products/Filters/CategoryProductsSorting";
import ProductsSideFilters from "@/components/Products/Filters/ProductsSideFilters";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { getProducts, ProductsFilter, ProductsPaginatedResponse } from "@/services/ProductsService";
import ProductCard from "@/components/ProductCard/ProductCard";
import Paginations from "@/components/Pagination/Pagination";
import LoadingProducts from "@/common/components/Loading/LoadingProducts";
import { FaExclamationTriangle } from "react-icons/fa";
import ProductsUtils, { ProductImageSizeType } from "@/common/utils/ProductsUtils";
import style from "./productList.module.scss";
import { getActiveFilters, getActiveSorting } from "@/common/helpers/fetch.helpers";
import { Configurators, ConfiguratorsPaginatedResponse } from "@/common/entities/configurators/Configurator";
import { getConfigurators } from "@/services/ConfiguratorService";
import ConfiguratorCard from "../ConfiguratorsProducts/ConfiguratorCard";
import { ProductsListType } from "@/common/entities/product/Product";

const ProductsAndConfiguratorsList = ({
    redirectUrl,
    isAtPromotion = false,
    isOutlet = false,
    showAll = false,
    type,
    brandId,
}: {
    redirectUrl: string | undefined;
    type: ProductsListType;
    showAll?: boolean;
    brandId?: number;
    isAtPromotion?: boolean;
    isOutlet?: boolean;
}) => {
    const baseProductsPerPage = 40;
    const [productsPerPage, setProductsPerPage] = useState(baseProductsPerPage);
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const router = useRouter();
    const scrollTop = useRef<HTMLDivElement>(null);
    const [products, setProducts] = useState<ProductsPaginatedResponse | undefined>(undefined);
    const [configurators, setConfigurators] = useState<ConfiguratorsPaginatedResponse | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
    const [from, setFrom] = useState(0);
    const [total, setTotal] = useState(0);
    const [searchParam, setSearchParam] = useState('');
    const observer = useRef<IntersectionObserver | null>(null);
    const [configuratorsCount, setConfiguratorsCount] = useState(0);
    const currentPage = Math.floor(from / productsPerPage) + 1;

    useEffect(() => {
        const fromParam = searchParams.get("from");
        const searchParamUrl = searchParams.get("search");
        if (fromParam) {
            setFrom(parseInt(fromParam, 10));
        }
        if(searchParamUrl){
            setSearchParam(searchParamUrl);
        }
    }, [searchParams]);

    const updateProductsFiltering = (filterParams: { field: string; value: string }[]) => {
        setFrom(0);
        const params = new URLSearchParams(searchParams.toString());

        filterParams.forEach(({ field, value }) => {
            if (value) {
                params.set(field, value);
            } else {
                params.delete(field);
            }
        });

        redirectFilterRoute(params.toString());
    };

    const removeIntervalFromFiltering = (filterParam: string) => {
        const params = new URLSearchParams(window.location.search);
        const intervals = params.get("intervals");

        if (intervals) {
            const updatedIntervals = intervals.split(",").filter((id) => id !== filterParam);
            if (updatedIntervals.length > 0) {
                params.set("intervals", updatedIntervals.join(","));
            } else {
                params.delete("intervals");
            }
        }
        redirectFilterRoute(params.toString());
    };

    const removeParentCategoryFromFiltering = () => {
        const params = new URLSearchParams(window.location.search);
        params.delete("siteCategoryParentId");
        redirectFilterRoute(params.toString());
    };

    const removeCharacteristicFromFiltering = (filterParam: string) => {
        const params = new URLSearchParams(window.location.search);
        const characteristics = params.get("characteristics");

        if (characteristics) {
            const updatedCharacteristics = characteristics.split(",").filter((id) => id !== filterParam);
            if (updatedCharacteristics.length > 0) {
                params.set("characteristics", updatedCharacteristics.join(","));
            } else {
                params.delete("characteristics");
            }
        }

        redirectFilterRoute(params.toString());
    };

    const onRemoveSearchFilter = () =>{
        const params = new URLSearchParams(window.location.search);
        params.delete("search");
        redirectFilterRoute(params.toString());
    };

    const redirectFilterRoute = (params: string) => {
        const currentPath = window.location.pathname;
        const newUrl = currentPath.includes("/page/all") ? `${currentPath}?${params}` : `/${redirectUrl}?${params}`;
        router.push(newUrl);
        scrollTop.current?.scrollIntoView({ behavior: "smooth" });
    };

    const onSort = (order: FilterOrderBy) => {
        updateProductsFiltering([
            { field: "orderBy", value: order.orderBy },
            { field: "orderDirection", value: order.orderDirection },
        ]);
    };

    const onCharacteristicsFilter = (characteristicId: number) => {
        const filters = getActiveFilters(searchParams);
        const ids = filters.characteristics?.split(",") || [];
        const newIds = ids.includes(characteristicId.toString())
            ? ids.filter((id) => id !== characteristicId.toString())
            : [...ids, characteristicId.toString()];

        updateProductsFiltering([
            { field: "characteristics", value: newIds.join(",") },
            { field: "from", value: "0" },
        ]);
    };

    const onParentCategoryFilter = (siteCategoryParentId: number) => {
        updateProductsFiltering([
            { field: "siteCategoryParentId", value: siteCategoryParentId.toString() },
            { field: "from", value: "0" },
        ]);
    };

    const onIntervalsFilter = (intervalId: number) => {
        const filters = getActiveFilters(searchParams);
        const ids = filters.intervals?.split(",") || [];
        const newIds = ids.includes(intervalId.toString()) ? ids.filter((id) => id !== intervalId.toString()) : [...ids, intervalId.toString()];

        updateProductsFiltering([
            { field: "intervals", value: newIds.join(",") },
            { field: "from", value: "0" },
        ]);
    };

    const fetchConfigurators = async () => {
        if (redirectUrl && !isAtPromotion) {
            const data = await getConfigurators(redirectUrl);
            setConfigurators(data);

            const count = data?.data?.length || 0;
            setConfiguratorsCount(count);
        }
    };

    const fetchProducts = useCallback(
        async (from: number) => {
            if (from >= total && total !== 0) return;

            setIsFetchingMore(true);
            setIsLoading(true);
            fetchConfigurators();

            const filters: ProductsFilter = {
                ...getActiveFilters(searchParams),
                ...getActiveSorting(searchParams),
                size: productsPerPage,
                from,
                isAtPromotion,
                isOutlet,
                brandId: brandId,
            };

            if (!filters.orderBy) {
                filters.orderBy = "products.availableQuantity";
                filters.orderDirection = "DESC";
            }

            const siteCategoryUrl =
                redirectUrl === "promotii" || redirectUrl === "outlet" ? undefined : type === ProductsListType.brand ? undefined : redirectUrl;

            try {
                const fetchedData = await getProducts({ ...filters, siteCategoryUrl });
                if (from === 0 || !showAll) {
                    setProducts(fetchedData);
                } else if (showAll && fetchedData?.data.length > 0) {
                    setProducts((prev) => ({
                        ...fetchedData,
                        data: [...(prev?.data || []), ...fetchedData.data],
                    }));
                }
                setTotal(fetchedData.total);
            } catch {
                setProducts(undefined);
            } finally {
                setIsLoading(false);
                setIsFetchingMore(false);
            }
        },
        [from, total, searchParams, productsPerPage, redirectUrl, showAll],
    );

    const lastElementRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (isLoading || isFetchingMore || from >= total || !showAll) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && !isFetchingMore) {
                    setFrom((prevFrom) => prevFrom + productsPerPage);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, isFetchingMore, total, showAll],
    );

    useEffect(() => {
        fetchProducts(from);
    }, [pathname, searchParams, from]);

    const handleSeeAll = () => {
        router.push(`/${redirectUrl}/page/all`);
        scrollTop.current?.scrollIntoView({ behavior: "smooth" });
    };

    const onPageChange = (page: number) => {
        const newFrom = (page - 1) * productsPerPage;
        setFrom(newFrom);

        if (page !== 1) {
            setProductsPerPage(baseProductsPerPage);
        }

        const params = new URLSearchParams(searchParams.toString());
        params.set("from", newFrom.toString());
        router.push(`/${redirectUrl}?${params.toString()}`);
    };

    const getConfiguratorCategoryUrl = (configurator: Configurators) => {
        return configurator?.category?.sitesCategories?.length
            ? configurator.category.sitesCategories[0].redirectUrl
            : configurator?.category?.url || "configurator";
    };

    const renderProducts = () => {
        if (isLoading || (isFetchingMore && from === 0)) {
            return <LoadingProducts rows={1} cols={4} />;
        }

        const noProductsOrConfigurators = (!products || products.data.length === 0) && (!configurators || configurators.data.length === 0);
        if (noProductsOrConfigurators) {
            return (
                <Row>
                    <Col>
                        <Alert variant="secondary" className="d-flex align-items-center">
                            <FaExclamationTriangle className="me-3" size={40} />
                            Nu s-au gasit produse sau configuratoare cu filtrarea actuala.
                        </Alert>
                    </Col>
                </Row>
            );
        }

        const totalItemsToShow = currentPage === 1 ? baseProductsPerPage : productsPerPage;
        const productsToShow =
            products && products.data ? (currentPage === 1 ? products.data.slice(0, totalItemsToShow - configuratorsCount) : products.data) : [];

        return (
            <Row>
                {currentPage === 1 &&
                    configurators?.data.map((configurator: Configurators, index) => (
                        <Col className={style.productCardsList} md={3} sm={3} xs={6} key={`configurator-${index}`}>
                            <ConfiguratorCard
                                imageSrc={configurator.configuratorImages.length ? configurator.configuratorImages[0].image.originalFileName : undefined}
                                configuratorName={configurator.name}
                                shortDescription={configurator.shortDescription}
                                url={`/${getConfiguratorCategoryUrl(configurator)}/` + configurator.url}
                            />
                        </Col>
                    ))}

                {productsToShow.map((product, index) => (
                    <Col
                        className={style.productCardsList}
                        md={3}
                        sm={3}
                        xs={6}
                        key={`product-${index}`}
                        ref={showAll && index === productsToShow.length - 1 ? lastElementRef : null}
                    >
                        <ProductCard
                            imageSrc={ProductsUtils.getProductCoverImage(ProductImageSizeType.medium, product.defaultImage)}
                            productName={product.name}
                            stock={product.availableQuantity || 0}
                            availableToOrder={product?.productStock?.availableToOrder}
                            price={product.specialPriceWithVat ? product.specialPriceWithVat : product.priceWithVat}
                            oldPrice={product.specialPriceWithVat ? product.priceWithVat : undefined}
                            currency={"RON"}
                            displayPrice={product.productStock?.displayPrice ?? false}
                            shortDescription={product.shortDescription}
                            url={ProductsUtils.getProductLink(product.url, product.categories) || ""}
                            productLabels={ProductsUtils.getProductLabels(product)}
                            type={product.type}
                        />
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        <>
            <Container ref={scrollTop}>
                <Row className="mt-md-4">
                    <Col md={3} className={style.filterSidebar}>
                        <Suspense
                            fallback={
                                <Container className="my-4">
                                    <LoadingProducts rows={2} cols={4} />{" "}
                                </Container>
                            }
                        >
                            <ProductsSideFilters
                                onRemoveCharacteristicFilter={removeCharacteristicFromFiltering}
                                onRemoveIntervalFilter={removeIntervalFromFiltering}
                                subCategoryUrl={redirectUrl}
                                filters={searchParams ? getActiveFilters(searchParams) : {}}
                                onCharacteristicsFilter={onCharacteristicsFilter}
                                onIntervalFilter={onIntervalsFilter}
                                isAtPromotion={isAtPromotion}
                                brandId={brandId}
                                onParentSiteCategoriesFilter={onParentCategoryFilter}
                                onRemoveParentCategoryFilter={removeParentCategoryFromFiltering}
                                siteParentCategoriesFilterEnabled={!!brandId || isAtPromotion || isOutlet}
                                isOutlet={isOutlet}
                                searchFilter={searchParam}
                                onRemoveSearchFilter={onRemoveSearchFilter}
                            />
                        </Suspense>
                    </Col>
                    <Col>
                        <Row className="align-items-center my-2 my-md-0">
                            <Col className="d-flex align-items-center justify-content-between mb-md-3">
                                <div className={style.productCount}>Total {total || "..."} produse</div>
                                <div className="d-flex align-items-center">
                                    <CategoryProductsSorting onSort={onSort} filters={getActiveSorting(searchParams)} />
                                </div>
                            </Col>
                        </Row>

                        {renderProducts()}

                        {!showAll && total > productsPerPage && (
                            <Paginations
                                allPagesNumber={Math.ceil(total / productsPerPage)}
                                currentPage={currentPage}
                                pageChange={onPageChange}
                                handleSeeAll={handleSeeAll}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ProductsAndConfiguratorsList;
