"use client";

import React from "react";
import style from "../../cateogry.module.scss";
import { SitesCategories } from "@/common/entities/sites/SiteCategories";
import { Form } from "react-bootstrap";

interface ParentCategoriesSideFilterProps {
    onParentCategoryFilter: (siteCategoryId: number, filterable?: boolean) => void;
    filters: { [key: string]: string | undefined };
    siteParentCategories: SitesCategories[];
}

const ParentCategoriesSideFilter = ({ onParentCategoryFilter, filters, siteParentCategories }: ParentCategoriesSideFilterProps) => {
    const isParentCategoryChecked = (siteCategoryParentId: number) => filters.siteCategoryParentId?.toString() === siteCategoryParentId.toString() || false;

    const renderParentCategories = () =>
        siteParentCategories.map((option, idx) => (
            <div className="d-flex justify-content-between align-items-center" key={idx}>
                <Form.Check
                    type="checkbox"
                    label={option.name}
                    id={`${option.name}-${idx}`}
                    onChange={() => onParentCategoryFilter(option.id)}
                    checked={isParentCategoryChecked(option.id)}
                />
                <small className="text-secondary">({option.totalProducts})</small>
            </div>
        ));

    return (
        <div className={style.cardFilter}>
            <h5 className={style.cardTitle}>Categorie</h5>
            {siteParentCategories && siteParentCategories.length > 0 && renderParentCategories()}
        </div>
    );
};

export default ParentCategoriesSideFilter;
