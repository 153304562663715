"use client";

import React from "react";
import { GroupedAttributesFilters } from "@/common/entities/product/Attributes";
import { SitesCategories } from "@/common/entities/sites/SiteCategories";
import ParentCategoriesSideFilter from "@/components/Products/Filters/Wrapper/ParentCategoriesSideFilter";
import IntervalAndCharacteristicsSideFilters
    from "@/components/Products/Filters/Wrapper/IntervalAndCharacteristicsSideFilters";

interface CharacteristicAndIntervalsFiltersProps {
    onIntervalFilter: (intervalId: number, filterable?: boolean) => void;
    onCharacteristicsFilter: (characteristicId: number, filterable?: boolean) => void;
    onParentCategoryFilter?: (siteParentCategoryId: number, filterable?: boolean) => void;
    filters: { [key: string]: string | undefined };
    attributesGroups: GroupedAttributesFilters[];
    sitesCategoriesFilterEnabled: boolean;
    parentSiteCategories?: SitesCategories[];
}

const SideFilters = ({
    onCharacteristicsFilter,
    onIntervalFilter,
    filters,
    attributesGroups,
                                               sitesCategoriesFilterEnabled,
                                               parentSiteCategories,
                                               onParentCategoryFilter
}: CharacteristicAndIntervalsFiltersProps) => {
    return (
        <>
            {sitesCategoriesFilterEnabled && onParentCategoryFilter && parentSiteCategories && (
                <ParentCategoriesSideFilter onParentCategoryFilter={onParentCategoryFilter} filters={filters} siteParentCategories={parentSiteCategories} />
            )}
            <IntervalAndCharacteristicsSideFilters onIntervalFilter={onIntervalFilter} filters={filters} attributesGroups={attributesGroups} onCharacteristicsFilter={onCharacteristicsFilter}/>

        </>
    );
};

export default SideFilters;
