import {Col, Row} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HCard from "../Card";

interface LoadingFiltersProps {
    cards: number;
}

const LoadingFilters = ({ cards = 1 }: LoadingFiltersProps) => {
    return (
        <>
            {Array.from({ length: cards }).map((_, rowIndex) => (
                <Row key={rowIndex}>
                    <Col key={rowIndex}>
                        <HCard className="mb-3">
                            <Skeleton height={24} style={{ marginTop: 20 }} />
                            <Skeleton height={20} style={{ marginTop: 10, marginBottom: 12 }} />
                            <Skeleton height={14} count={3} style={{ marginTop: 4 }} />
                        </HCard>
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default LoadingFilters;
